@import 'libs/uikit/src/styles/global';

.container {
  .header {
    position: relative;
    display: grid;
    place-items: center;
    height: calc(100vh - 64px);
    width: 100%;
    margin: 0 auto;
    margin-bottom: 16px;

    .video {
      position: absolute;
      z-index: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::-webkit-media-controls {
        display: none;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      padding-bottom: 90px;
      text-align: center;
      height: 100%;
      width: 100%;
      padding: 50px;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 0.5494791666666667) 16%,
        rgba(0, 0, 0, 0) 100%
      );
      color: var(--color-white);
      font-size: rem(26px);
      font-weight: 900;
      z-index: 1;

      .title {
        font-size: rem(64px);
        color: var(--color-white);
        margin-bottom: 33px;
        line-height: rem(57px);
      }

      .tag {
        background-color: var(--color-orange-400);
        border-radius: 4px;
        padding: 4px 8px;
        color: var(--color-greyscale-600);
        margin-right: 8px;
      }

      .btn {
        margin: 22px 0;
        padding: 0 auto;
        font-size: rem(26px);
        width: 164px;

        @include media('<md') {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }
  }

  .seo-link {
    list-style: none;
    text-align: center;
    padding: 0px;
    margin-bottom: 0;
    margin-top: 14px;

    li {
      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .center {
    margin: auto;
  }

  .image {
    max-width: 100%;
    height: auto;
  }

  .section-why-pigbet {
    .title {
      font-size: rem(28px);
      margin-top: 14px;
    }
  }

  .partner {
    .image-wrapper {
      padding: 0px;
    }
  }

  .center {
    text-align: center;
  }

  .contact-us {
    margin-top: 16px;
  }
}
